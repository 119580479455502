@import "../../vars";
@import "../../bootstrap-core";

.pet-list {
  background-color: $app-bg;

  form {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
    margin-bottom: 1rem;

    .form-group {
      margin: 0;
    }
  }

  .card {
    flex: 0 1 300px;
    cursor: pointer;

    &:active, &:hover {
      background-color: $app-bg-darker;
    }
  }

  #cards {
    padding-bottom: 2rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;

    .card-body {
      padding: 0.75rem;
      text-align: center;
    }
  }
}
