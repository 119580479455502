@import "../../vars";
@import "../../bootstrap-core";

.pet-viewer__picture-container {
  width: 100%;
  grid-row: 1 / span 3;
  border-radius: 5px;
  overflow: hidden;
  aspect-ratio: 3 / 2;
  box-shadow: 0 0 10px 0 rgba(0,0,0,.5);
}

.pet-viewer__grid {
  display: grid;
  grid-template-columns: 300px repeat(3, 1fr);
  grid-gap: 1.5rem;

  table {
    table-layout: fixed;
    grid-column: auto / span 2;
  }

  td, th {
    padding: 0.5rem 0;
  }

  #notes {
    width: 100%;
    grid-row: 4 / span 2;
    grid-column: 1 / span 2;
    word-wrap: break-word;
  }

  #status {
    grid-column: 3 / span 2;
    grid-row: 5;

    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr auto;

    h3 {
      align-self: end;
      margin-bottom: 0;
      grid-column: 1 / span 2;
    }
  }

  @include media-breakpoint-down(md) {
    grid-template-columns: 300px 1fr 1fr;

    #status {
      grid-column-start: 1;
      grid-row: auto;
    }

    #notes {
      grid-column-start: 2;
      grid-row-start: 5;
    }
  }

  @include media-breakpoint-down(sm) {
    grid-template-columns: 300px 1fr;

    #notes {
      grid-column-start: 1;
      grid-row: auto;
    }
  }

  @include media-breakpoint-down(xs) {
    grid-template-columns: 1fr;

    table {
      grid-column: 1;
    }

    #notes, #status {
      grid-column: auto;
    }
  }
}

.pet-viewer {
  background-color: $app-bg;
}

.field {
  white-space: nowrap;
}
