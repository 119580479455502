@import "./bootstrap-core";

//Theme
$app-primary-light: #EDEDF5;
$app-primary: #EDE8EF;
$app-primary-dark: #705697;

$app-secondary-light: #d3dbcd;
$app-secondary: #c4d9b1;
$app-secondary-dark:  #b1cc97;
$app-secondary-darker: #a6b39b;

$app-bg-light: white;
$app-bg: #F5F5F5;
$app-bg-dark: #F2F2F2;
$app-bg-darker: #ececec;

$app-text-light:#8e8e90;
$app-text: #616161;
$app-text-dark: #333333;

$app-hover-bg-light: rgba(white, .1);
$app-hover-bg-dark: rgba(#333333, .1);

$app-hover-overlay-dark: rgba(#333333, .5);

$app-border: #DCDCDC;
$app-border-dark: #d1d1d1;

//Sizing
$app-em: 12.8px;

$app-navbar-height: 52px;

//@mixin angleDivider($height) {
//  padding-bottom: $height;
//  margin-bottom: -$height;
//  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - #{$height}));
//
//  & + * {
//    padding-top: $height;
//  }
//}
