#notFoundPage {
  height: 100vh;
  overflow: auto;
  background-image: url("../../assets/404.jpg");
  background-size: cover;
  background-position: top left;

  #message {
    margin-top: 3rem;
    font-size: 1.5rem;
  }

  #statusCode {
    font-weight: 100;
    line-height: 1;
    font-size: min(32vh, 32vw);
  }
}
