@import "../../vars";
@import "../../bootstrap-core";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $app-text-dark;
}

html {
  overflow-y: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.clickable {
  cursor: pointer;
}

.form-group label {
  white-space: nowrap;
}

.custom-spinner {
  padding-left: 2.25rem;

  .custom-control-label::before {
    background-color: transparent;
    border: 2px solid $primary;
    border-radius: 50%;
    border-right-color: transparent;
    animation: spinner-border 0.75s linear infinite;
  }
}

.dropdown-menu {
  max-height: 280px;
  overflow-y: auto;
}

.tooltip-inner {
  max-width: 300px;
}

.page {
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.page__container {
  flex: 1 1 auto;
}
