@import "../../vars";

.formPage__form {
  flex: 0 1 400px;
  margin: 2rem 1rem;
  padding: 2rem 1.5rem;
  background-color: $light;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.formPage__container {
  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url("../../assets/formBg.jpg");
  background-size: cover;
  background-position: top center;
}

.formPage__heading {
  margin-bottom: 1rem;
}
