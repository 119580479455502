@import "../../bootstrap-core";
@import "../../vars";

.settingsPage__email {
  margin-left: auto;
}

.settingsPage__container {
  flex: 1 1 auto;
}

.settingsPage__row {
  background-color: $app-bg;
}

.settingsPage__selector {
  background-color: $app-bg-darker;
}

.settingsPage__content {
  overflow: hidden;
}

.settingsPage__heading {
  font-size: 2.75rem;
  font-weight: 300;
}

.settingsPage__section__heading {
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 0.5rem;
  width: 100%;
}

.settingsPage__section__sub-heading {
  font-weight: bold;
  margin-bottom: 0.25rem;
}

.settingsPage__list.list-group .list-group-item {
  background-color: transparent;
  white-space: nowrap;

  &.selected {
    box-shadow: inset 0 -3px 0 0 $app-secondary-dark;
    background-color: $app-secondary;
  }
}

.settingsPage__table.rst-container {
  height: 500px;
  width: 100%;
}

.settingsPage__input-grid {
  display: grid;
  grid-column-gap: 1rem;
  grid-template-columns: 1fr;

  @include media-breakpoint-up(sm) {
    grid-template-columns: 1fr 1fr;
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.settingsPage__button-strip {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.plan__heading {
  font-size: 1.25rem;
  padding: 0.5rem 1rem;
  border-width: 1px;
  border-bottom-style: solid;
  border-color: inherit;
  margin: 0;
}

.plan__price {
  font-size: 1.25rem;
  color: $app-text;
  padding: 0.5rem 1rem;
}

.plan.card {
  cursor: pointer;
  text-align: center;
  overflow: hidden;

  &.selected {
    border-color: $app-primary-dark;

    .plan__heading {
      background-color: $app-primary-light;
    }
  }
}

.settingsPage__form {
  width: 300px;
}

.help-icon {
  display: inline-block;
  height: 1rem;
  margin-left: 0.5rem;
  vertical-align: middle;
  cursor: help;
}

.settingsPage__subscription {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;

  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr 300px;
  }
}

.settingsPage__subscription__buyer {
  grid-column-end: -1;
  grid-row-end: span 3;
}

.settingsPage__subscription__buyer__form {
  display: grid;
  column-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.settingsPage__subscription__plans__list {
  display: grid;
  grid-template-columns: repeat(auto-fit, 150px);
  gap: 1rem;
  justify-content: center;
}

.settingsPage {
  min-height: 100vh;
  height: 0; // Fix for chrome
  display: flex;
  flex-direction: column;

  &::before {
    z-index: -1;
    content: '';
    position: fixed;
    inset: 0;
    background: linear-gradient(to right, $app-bg-darker 50%, $app-bg 50%);
  }
}
