@import '../../vars';

$pd-navbar__content-height: 2.5rem;

.pd-navbar {
  background-color: $app-primary-dark;
  color: white;

  z-index: 1;
  height: $pd-navbar__content-height;
  padding: 0.5rem 0;
  box-sizing: content-box;
  flex: none;
}

.pd-navbar__container {
  display: flex;
  align-items: center;
  height: 100%;
}

.pd-navbar__logo {
  height: 100%;
  fill: white;
}

.pd-navbar__home-link {
  align-self: stretch;
  display: contents;
}

.pd-navbar__link {
  margin-left: 1rem;
  color: inherit;
  text-decoration: underline;

  &:hover {
    color: unset;
  }
}

.pd-navbar__alert {
  padding: 0 1rem;
  margin-right: $pd-navbar__content-height;
  flex: 1 1 auto;
  text-align: center;
}
